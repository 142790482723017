<template>
  <div class="manager-works-page">
    <div class="header-bar">
      <div class="search-wrapper">
        <el-form
          :inline="true"
          :model="searchForm"
          class="search-form"
          size="mini"
          ref="searchForm"
        >
          <!-- @change="getFolderList(true)" -->
          <el-form-item label="">
            <el-select
              v-model="searchForm.cids"
              placeholder="按作品领域"
              size="mini"
              style="width: 100%"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="">
            <el-input
              size="mini"
              v-model="searchForm.search"
              placeholder="作品集名称"
            ></el-input>
          </el-form-item>
          <el-form-item label="">
            <el-date-picker
              size="mini"
              v-model="searchForm.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              @click="handleSearch"
              size="mini"
              class="green-btn"
              >查询</el-button
            >
            <el-button
              @click="handleRefresh"
              size="mini"
              class="green-plain-btn"
              >刷新</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="collection-wrapper" v-loading="isLoading">
      <ul
        class="collection-list"
        v-infinite-scroll="loadMore"
        :infinite-scroll-disabled="busy"
      >
        <li
          v-for="item in folderList"
          :key="item.id"
          @click="gotoCollection(item)"
        >
          <div class="img-wrapper">
            <div class="left-wrapper">
              <img
                :src="item.thumbnails[0]"
                alt=""
                v-if="item.thumbnails && item.thumbnails[0]"
              />
            </div>
            <div class="right-wrapper" v-if="item.thumbnails.length >= 2">
              <div class="img-item">
                <img
                  :src="item.thumbnails[1]"
                  alt=""
                  v-if="item.thumbnails && item.thumbnails[1]"
                />
              </div>
              <div class="img-item" v-if="item.thumbnails.length >= 3">
                <img
                  :src="item.thumbnails[2]"
                  alt=""
                  v-if="item.thumbnails && item.thumbnails[2]"
                />
              </div>
            </div>
          </div>
          <div class="info-wrapper">
            <div class="info-item" @click.stop="handleUninstallWorks(item)">
              <span class="title ellipsis" style="max-width: 150px">{{
                item.name
              }}</span>
              <div class="uninstall">
                <i
                  class="iconfont icon-xiajia"
                  style="color: #814dff; margin-right: 6px"
                  @click.stop
                ></i>
                下架
              </div>
            </div>
            <div class="info-item info-last">
              <span class="count-wrapper">
                <div
                  v-if="item.pictureCount > 0"
                  :title="'图片：' + item.pictureCount"
                >
                  <img
                    src="../../../../assets/images/icon/icon_pic.png"
                    alt=""
                    class="icon-tupian"
                  /><span>{{ item.pictureCount }}</span>
                </div>
                <el-divider
                  direction="vertical"
                  v-if="item.pictureCount && item.modelCount"
                ></el-divider>
                <div
                  v-if="item.modelCount > 0"
                  :title="'模型：' + item.modelCount"
                >
                  <img
                    src="../../../../assets/images/icon/icon_models.png"
                    alt=""
                    class="icon-model"
                  />
                  <span>{{ item.modelCount }}</span>
                </div>
              </span>
              <span
                class="user-name"
                @click.stop="$router.push('/community/designer/' + item.userId)"
                >{{ item.nickName }}</span
              >
            </div>
          </div>
        </li>
      </ul>

      <Empty v-if="isEmpty" description="暂无相关图集" />
    </div>
    <el-dialog
      :visible.sync="dialogFormShow"
      :close-on-click-modal="false"
      custom-class="form-dialog"
      :before-close="onCancel"
      title="下架原因"
      width="30%"
    >
      <el-form :model="form" :rules="rules" ref="uninstallForm" class="x-form">
        <el-form-item prop="type">
          <el-radio-group v-model="form.type">
            <el-radio :label="1">不合规作品</el-radio>
            <el-radio :label="0">其他原因</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item prop="comment">
          <el-input
            type="textarea"
            show-word-limit
            :maxlength="255"
            v-model="form.comment"
            placeholder="请填写详细原因"
            :autosize="{ minRows: 2, maxRows: 10 }"
          ></el-input>
        </el-form-item>
        <el-form-item class="btn-group">
          <el-button plain class="s-btn" @click="onSubmit">提交</el-button>
          <el-button class="c-btn" @click="onCancel">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import { EventBus } from "@/EventBus";
export default {
  data() {
    return {
      dialogFormShow: false,
      form: {
        type: 1,
        comment: "",
      },
      rules: {
        type: [{ required: true, message: "请选择类型", trigger: "change" }],
        comment: [
          { required: true, message: "请填写详细原因", trigger: "blur" },
        ],
      },
      SUCCESS_CODE: 0,
      options: [],
      svalue: "",
      svalue1: "",
      isLoading: false,
      isEmpty: false,
      folderList: [],
      busy: false,
      pageParams: {
        pageNum: 1,
        pageSize: 20,
        total: 0,
        currentPage: 0,
      },
      searchForm: {
        search: "",
        time: [],
        cids: "",
      },
      beforeUninstallData: {},
    };
  },
  created() {
    this.getTypeOptions();
    this.getFolderList(true);
  },
  mounted() {
    EventBus.$on("setWorkPage", (data) => {});
  },
  methods: {
    onSubmit() {
      this.$refs.uninstallForm.validate((valid) => {
        if (valid) {
          this.$confirm("此操作将下架该作品, 是否继续?", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
            center: true,
          })
            .then(() => {
              this.uninstallWorks();
            })
            .catch(() => {});
        } else {
          return;
        }
      });
    },
    onCancel() {
      this.dialogFormShow = false;
      this.$refs.uninstallForm.resetFields();
    },
    //下架
    uninstallWorks() {
      let params = {
        directoryId: this.beforeUninstallData.id,
        type: this.form.type,
        comment: this.form.comment,
        status: 1,
      };
      this.$http
        .post("/resource-info/offShelfByDirectory", null, { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.$message.success("下架成功！");
            this.$refs.uninstallForm.resetFields();
            this.onCancel();
            this.getFolderList(true);
          } else {
            this.$message.error("操作失败！");
          }
        })
        .catch((err) => {
          this.$message.error("操作失败！");
        });
    },
    handleUninstallWorks(data) {
      this.beforeUninstallData = data;
      this.dialogFormShow = true;
    },
    //   获取类型options
    getTypeOptions() {
      this.$http
        .get("/dict-category/list")
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.options = res.data;
          }
        })
        .catch((err) => {});
    },
    // 获取图集
    getFolderList(flag) {
      if (flag) {
        this.folderList = [];
        this.pageParams = {
          pageNum: 1,
          pageSize: 20,
          total: 0,
          currentPage: 0,
        };
      }
      if (this.busy) return;
      if (
        this.pageParams.currentPage * this.pageParams.pageSize >
        this.pageParams.total
      ) {
        return;
      }
      this.isLoading = true;
      this.busy = true;
      let params = Object.assign({}, this.pageParams);
      params.cids = this.searchForm.cids;
      params.search = this.searchForm.search;
      if (this.searchForm.time && this.searchForm.time.length) {
        params.start = this.searchForm.time[0];
        params.end = this.searchForm.time[1];
      }
      this.$http
        .get("/directory-info/publicDir", { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            res.data.records.map((folder) => {
              folder.thumbnails = folder.resourceInfos.map((resouce) => {
                return resouce.obsThumbUrl;
              });
            });
            this.folderList = this.folderList.concat(res.data.records);
            this.pageParams.total = res.data.total;
            this.pageParams.currentPage = res.data.current;
            if (this.folderList && this.folderList.length) {
              this.isEmpty = false;
            } else {
              this.isEmpty = true;
            }
          } else {
            this.isEmpty = true;
          }
          this.isLoading = false;
          this.busy = false;
        })
        .catch((err) => {
          this.isEmpty = true;
          this.isLoading = false;
          this.busy = false;
        });
    },
    loadMore() {
      this.pageParams.pageNum++;
      this.getFolderList();
    },
    // 搜索
    handleSearch() {
      console.log(this.searchForm);
      this.getFolderList(true);
    },
    // 刷新
    handleRefresh() {
      this.$refs.searchForm.resetFields();
      this.searchForm = {
        search: "",
        time: [],
        cids: "",
      };
      this.getFolderList(true);
    },
    gotoCollection(item) {
      // 跳转图集页面
      let routeData = this.$router.resolve({
        path:
          "/manager/works/collection/detail/" + item.id + "?type=" + this.type,
      });
      window.open(routeData.href, "_blank");
    },
  },
  beforeDestroy() {
    this.folderList = [];
    this.pageParams = {
      pageNum: 1,
      pageSize: 20,
      total: 0,
      currentPage: 0,
    };
  },
};
</script>
